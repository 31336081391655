import PDF from './pdf'
import store from 'store'
import { formatDate } from 'utils/utils'
import { numberName } from 'utils/utils'
import { fuentesFinanciamiento, recursosObj } from 'data/const'
import { formatCurrency } from 'utils/utils'

class ReembolsoPDF extends PDF {

    seccion = 'Sección Caja'

    /**
     * Genera el rembolso de viáticos en PDF
     * @param {object} reembolso Datos del reembolso
     * @param {string} rembolso.numero_reembolso
     * @param {string} rembolso.actividad
     * @param {string} rembolso.recursos
     * @param {object[]} rembolso.papeletas
     */
    reembolsoViaticos(reembolso) {
        const { userInfo: { fuente_financiamiento } } = store.getState().auth
        const title = 'REEMBOLSO DE VIÁTICOS'
        const {
            numero_reembolso, recursos, unidad, papeletas, 
            total_pasaje_aereo, total_pasaje_terrestre,
            total_monto_viaticos, total_monto, jefe_caja
        } = reembolso
        const tableHeader = [
            { text: ['Fecha', '\n', 'Emisión'], style: ['stretch-header']}, 
            { text: ['Número', '\n', 'Viático'], style: ['stretch-header']},  
            { text: 'Nombre', style: ['stretch-header', 'mt-1'] },
            { text: 'Lugar', style: ['stretch-header', 'mt-1'] }, 
            { text: ['Pasaje', '\n', 'Aéreo'], style: ['stretch-header']},  
            { text: ['Pasaje', '\n', 'Terrestre'], style: ['stretch-header']},  
            { text: 'Viáticos', style: ['stretch-header', 'mt-1']},  
            { text: 'Total', style: ['stretch-header', 'mt-1']},
        ]
        const content = [
            { 
                text: [
                    'RECURSOS ', recursosObj[recursos].toUpperCase(), '\n',
                    unidad, '\n',
                    'Reembolso número: ' , numero_reembolso
                ],
                lineHeight: 1.25, marginLeft: 25
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        tableHeader,
                        ...papeletas.map(papeleta => [
                                { text: formatDate(papeleta.fecha_registro, 'd-M-Y'), noWrap: true },
                                { text: papeleta.numero_papeleta, style: ['text-center'] },
                                { 
                                    text: [
                                        papeleta.apellido_beneficiario.trim(), 
                                        ' ', 
                                        papeleta.nombre_beneficiario.trim()
                                    ],
                                    style: ['font-roboto-c']
                                },
                                { text: (papeleta.lugar_viaje||'').toUpperCase(), style: ['font-roboto-c']},
                                { text: formatCurrency(papeleta.pasaje_aereo_devolucion), style: ['text-end'] },
                                { text: formatCurrency(papeleta.pasaje_terrestre_devolucion), style: ['text-end'] },
                                { text: formatCurrency(papeleta.monto_viaticos), style: ['text-end'] },
                                { text: formatCurrency(papeleta.monto), style: ['text-end'] },
                            ]
                        ),
                        [
                            '', '', 
                            { text: 'TOTAL', style: ['text-center', 'fw-bold'] }, 
                            '',
                            { text: formatCurrency(total_pasaje_aereo), style: ['text-end', 'fw-bold'] }, 
                            { text: formatCurrency(total_pasaje_terrestre), style: ['text-end', 'fw-bold'] }, 
                            { text: formatCurrency(total_monto_viaticos), style: ['text-end', 'fw-bold'] }, 
                            { text: formatCurrency(total_monto), style: ['text-end', 'fw-bold'] }, 
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i < 2 || i >= node.table.body.length-1) ? 1 : 0
                    },
                    vLineWidth: () => 0,
                    paddingTop: (i, node) => {
                        return 2
                    },
                },
                marginBottom: 50,
                style: ['font-roboto-c']
            },
            { 
                text: ['Reembolso por: ', numberName(total_monto).toUpperCase(), '00/100 Bolivianos.'], 
                marginBottom: 75 
            },
            {
                columns: [
                    {
                        width: '*', 
                        text: [
                            fuente_financiamiento === 'DAF' ? jefe_caja : '',
                            '\n', 
                            {
                                text: fuente_financiamiento === 'DAF' ? 'JEFE DE CAJA' : 'ENCARGADO DE CAJA',
                                style: ['fw-bold']
                            },
                            '\n',
                            'FIRMA',
                        ], 
                        style: ['text-center']
                    },
                    {
                        width: '*', 
                        text: ''
                    }
                ]
            }
        ]
        this.generateReport(title, content)
    }

    reembolsoViaticosDetallado(reembolso) {
        const { userInfo: { fuente_financiamiento } } = store.getState().auth
        const title = 'REEMBOLSO DE VIÁTICOS'
        const {
            numero_reembolso, recursos, unidad, papeletas, 
            total_aereo, total_terrestre, total_devolucion_terrestre, total_pasaje_terrestre,
            total_devolucion_aereo, total_pasaje_aereo,
            total_viaticos, total_devolucion_viaticos,
            total_monto_viaticos, total_monto, jefe_caja
        } = reembolso
        const getTableHeader = () => {
            let tableHeader = [
                { text: ['Fecha', '\n', 'Emisión'], style: ['stretch-header']}, 
                { text: ['Número', '\n', 'Viático'], style: ['stretch-header']},  
                { text: 'Nombre', style: ['stretch-header', 'mt-1'] },
                { text: 'Lugar', style: ['stretch-header', 'mt-1'] }, 
            ]
            if (total_devolucion_aereo > 0) {
                tableHeader.push(
                    { text: ['Pasaje', '\n', 'Aéreo'], style: ['stretch-header']},
                    { text: ['Devolución', '\n', 'Pas. Aéreo'], style: ['stretch-header']},
                    { text: [{ text: 'Pas. Aéreo', noWrap: true }, '\n', 'Utilizado'], style: ['stretch-header']},
                )
            } else {
                tableHeader.push({ text: ['Pasaje', '\n', 'Aéreo'], style: ['stretch-header']})
            }
            if (total_devolucion_terrestre > 0) {
                tableHeader.push(
                    { text: ['Pasaje', '\n', 'Terrestre'], style: ['stretch-header']},
                    { text: ['Devolución', '\n', 'Terrestre'], style: ['stretch-header']},
                    { text: ['Terrestre', '\n', 'Utilizado'], style: ['stretch-header']},
                )
            } else {
                tableHeader.push({ text: ['Pasaje', '\n', 'Terrestre'], style: ['stretch-header']})
            }
            if (total_devolucion_viaticos > 0) {
                tableHeader.push(
                    { text: 'Viáticos', style: ['stretch-header', 'mt-1']},
                    { text: ['Devolución', '\n', 'Viáticos'], style: ['stretch-header']},
                    { text: ['Viáticos', '\n', 'Utilizado'], style: ['stretch-header']},
                )
            } else {
                tableHeader.push({ text: 'Viáticos', style: ['stretch-header', 'mt-1']})
            }
            tableHeader.push({ text: 'Total', style: ['stretch-header', 'mt-1']})
            return tableHeader
        }
        const tableHeader = getTableHeader()
        if (tableHeader.length > 8) {
            const buildRow = papeleta => {
                let row = [
                    { 
                        text: formatDate(papeleta.fecha_registro, 'd-M-Y'), 
                        style: ['text-center', 'font-roboto-c'],
                        noWrap: true 
                    },
                    { text: papeleta.numero_papeleta, style: ['text-center'] },
                    { text: [papeleta.apellido_beneficiario.trim(), ' ', papeleta.nombre_beneficiario.trim()], style: ['font-roboto-c'] },
                    { text: (papeleta.lugar_viaje||'').toUpperCase(), style: ['font-roboto-c'] },
                ]
                if (total_devolucion_aereo > 0) {
                    row.push(
                        { text: formatCurrency(papeleta.pasaje_aereo), style: ['text-end'] },
                        { text: formatCurrency(papeleta.devolucion_aereo), style: ['text-end'] },
                    )
                }
                row.push({ text: formatCurrency(papeleta.pasaje_aereo_devolucion), style: ['text-end'] })
                if (total_devolucion_terrestre > 0) {
                    row.push(
                        { text: formatCurrency(papeleta.pasaje_terrestre), style: ['text-end'] },
                        { text: formatCurrency(papeleta.devolucion_terrestre), style: ['text-end'] },
                    )
                }
                row.push({ text: formatCurrency(papeleta.pasaje_terrestre_devolucion), style: ['text-end'] })
                if (total_devolucion_viaticos > 0) {
                    row.push(
                        { text: formatCurrency(papeleta.subtotal_viaticos), style: ['text-end'] },
                        { text: formatCurrency(papeleta.devolucion_viaticos), style: ['text-end'] },
                    )
                }
                row.push(
                    { text: formatCurrency(papeleta.monto_viaticos), style: ['text-end'] },
                    { text: formatCurrency(papeleta.monto), style: ['text-end'] }
                )
                return row
            }
            const getRowTotales = () => {
                let totales = [
                    '', '', 
                    { text: 'TOTALES', style: ['text-center', 'fw-bold', 'font-roboto-c'] }, 
                    '',
                ]
                if (total_devolucion_aereo > 0) {
                    totales.push(
                        { text: formatCurrency(total_aereo), style: ['text-end'] },
                        { text: formatCurrency(total_devolucion_aereo), style: ['text-end'] },
                    )
                }
                totales.push({ text: total_pasaje_aereo, style: ['text-end', 'fw-bold'] })
                if (total_devolucion_terrestre > 0) {
                    totales.push(
                        { text: formatCurrency(total_terrestre), style: ['text-end'] },
                        { text: formatCurrency(total_devolucion_terrestre), style: ['text-end'] },
                    )
                }
                totales.push({ text: total_pasaje_terrestre, style: ['text-end', 'fw-bold'] })
                if (total_devolucion_viaticos > 0) {
                    totales.push(
                        { text: formatCurrency(total_viaticos), style: ['text-end'] },
                        { text: formatCurrency(total_devolucion_viaticos), style: ['text-end'] },
                    )
                }
                totales.push(
                    { text: formatCurrency(total_monto_viaticos), style: ['text-end', 'fw-bold'] }, 
                    { text: formatCurrency(total_monto), style: ['text-end', 'fw-bold'] }
                )
                return totales
            }
            const content = [
                { 
                    text: [
                        'RECURSOS ', recursosObj[recursos].toUpperCase(), '\n',
                        unidad, '\n',
                        'Reembolso número: ' , numero_reembolso
                    ],
                    lineHeight: 1.25, marginLeft: 25
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', 'auto', 'auto', ...(new Array(tableHeader.length-5).fill('auto'))],
                        body: [
                            tableHeader,
                            ...papeletas.map(buildRow),
                            getRowTotales()
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i < 2 || i >= node.table.body.length-1) ? 1 : 0
                        },
                        vLineWidth: () => 0,
                    },
                    marginBottom: 50,
                    style: ['font-roboto-c']
                },
                { 
                    text: ['Reembolso por: ', numberName(total_monto).toUpperCase(), '00/100 Bolivianos.'], 
                    marginBottom: 75 
                },
                {
                    columns: [
                        {
                            width: '*', 
                            text: [
                                fuente_financiamiento === 'DAF' ? jefe_caja : '',
                                '\n', 
                                {
                                    text: fuente_financiamiento === 'DAF' ? 'JEFE DE CAJA' : 'ENCARGADO DE CAJA',
                                    style: ['fw-bold']
                                },
                                '\n',
                                'FIRMA',
                            ], 
                            style: ['text-center']
                        },
                        {
                            width: '*', 
                            text: ''
                        }
                    ]
                }
            ]
            this.generateReport(title, content, {config: {pageOrientation: 'landscape'}})
        } else {
            this.reembolsoViaticos(reembolso)
        }
    }

    /**
     * Genera la lista de reembolsos en PDF
     * @param {array} reembolsos Datos del reembolso
     * @param {object} filters Filtros de busqueda de reembolsos
     */
    listaReembolsos(reembolsos, title, filters) {
        const content = this.cheque(reembolsos)
        this.generateReport(title||'Lista de reembolsos', content, {config: {pageMargins: [42, 21, 42, 21]}})
    }

    listaEmitidos(reembolsos, filters) {
        const content = [
            {
                table: {
                    headerRows: 1,
                    widths: [60, '*', 'auto', 'auto', 120],
                    body: [
                        [
                            { text: 'FECHA', style: ['text-center', 'fw-bold']}, 
                            { text: 'DETALLE', style: ['text-center', 'fw-bold']},  
                            { text: 'UNIDAD', style: ['text-center', 'fw-bold'] },
                            { text: 'MONTO', style: ['text-center', 'fw-bold'] }, 
                            { text: 'FIRMA', style: ['text-center', 'fw-bold']}, 
                        ],
                        ...reembolsos.map(reembolso => [
                            { text: formatDate(reembolso.fecha_creacion, 'd-M-y'), noWrap: true },
                            { text: [reembolso.numero_reembolso, '    ', 'Reembolso de viáticos'] },
                            { text: reembolso.unidad},
                            { text: formatCurrency(reembolso.monto), style: 'text-end' },
                            ''
                        ])
                    ]
                },
                layout: {
                    hLineWidth: () => 1,
                    vLineWidth: () => 0,
                    hLineStyle: (i) => i>1 ? {dash: {length: 4, space: 2}} : null,
                    paddingTop: (i) => i>0? 20 : 2,
                    paddingBottom: (i) => i>0? 20 : 2
                },
            },
        ]
        this.generateReport('LISTA DE REEMBOLSOS EMITIDOS', content, {config: {pageMargins: [42, 21, 42, 21]}})
    }

    cheque(reembolsos) {
        let total = 0
        const getRows = () => {
            return reembolsos.map(reembolso => {
                total += 1*reembolso.monto
                return [
                    { text: formatDate(reembolso.fecha_creacion, 'd/m/Y'), style: ['text-center'] },
                    { text: reembolso.numero_reembolso, style: ['text-center'] },
                    { text: reembolso.unidad_nombre_corto||reembolso.unidad },
                    { text: formatCurrency(reembolso.monto), style: 'text-end' },
                    { text: reembolso.fecha_anulacion ? 'Anulado' : '' },
                ]
            })
        }
        return [
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', '*', 'auto', 40],
                    body: [
                        [
                            { text: 'Fecha Registro', style: ['text-center', 'fw-bold']},  
                            { text: 'Nro. Reembolso', style: ['text-center', 'fw-bold']},  
                            { text: 'Unidad', style: ['text-center', 'fw-bold'] },
                            { text: 'Monto', style: ['text-center', 'fw-bold'] },
                            { text: 'Obs.', style: ['text-center', 'fw-bold'] },
                        ],
                        ...getRows(),
                        [
                            { text: 'TOTAL', style: ['text-center', 'fw-bold'], colSpan: 3 },
                            {},
                            {},
                            { text: formatCurrency(total), style: ['fw-bold'] },
                            { text: '' },
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i < 2 || i >= node.table.body.length-1 ? 1 : 0
                    },
                    vLineWidth: function (i) {
                      return 0;
                    },
                },
            },
        ]
    }

    solicitudReembolso(reembolso) {
        const { userInfo: { fuente_financiamiento } } = store.getState().auth
        const {numero_reembolso, fecha_creacion, unidad, beneficiario,
            recursos, monto_total, destinatario, cargo_destinatario,
            jefe_caja, jefe_tesoreria, beneficiario_reembolso} = reembolso
        const content = [
            {text: [
                fuente_financiamiento === 'DAF' ? 'DEPARTAMENTO DE TESORERIA' : fuentesFinanciamiento[fuente_financiamiento]?.toUpperCase(),
                '\n',
                this.seccion, '\n',
                'Cochabamba - Bolivia', '\n',
                '---------------------------------------------'
            ], style: ['fw-bold'], marginBottom: 50},
            {text: ['Cochabamba, ', formatDate(new Date(), 'd/m/Y')]},
            {text: ['Caja-V ', numero_reembolso, '/', formatDate(fecha_creacion, 'y')], marginBottom: 10},
            {text: [
                'Señor:', '\n',
                destinatario, '\n',
                cargo_destinatario, '\n',
                'Presente.-'
            ], marginBottom: 20},
            {text: 'REF.: SOLICITUD DE REEMBOLSO', marginBottom: 5},
            {
                text: [
                    'De mi consideración:', '\n',
                    'Mediante la presente solicito a su autoridad instruya a quien corresponda la emisión de cheques de acuerdo  al siguiente detalle'
                ], style: ['text-justify']
            },
            {
                table: {
                    widths: ['*', '*', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            {text: 'BENEFICIARIO', style: ['text-center']}, 
                            {text: 'DETALLE', style: ['text-center']}, 
                            {text: 'RECURSOS', style: ['text-center']}, 
                            {text: 'UNIDAD', style: ['text-center']}, 
                            {text: 'MONTO (Bs)', style: ['text-center']}
                        ],
                        [
                            fuente_financiamiento === 'DAF' ? beneficiario_reembolso : beneficiario,
                            'PAGO PASAJES Y VIÁTICOS', 
                            {text: recursosObj[recursos]}, 
                            unidad, 
                            {text: formatCurrency(monto_total), style: ['text-end']}
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i < 2 || i === node.table.body.length ? 1 : 0
                    },
                    vLineWidth: function (i) {
                      return 0;
                    },
                },
                style: ['my-3']
            },
            {text: 'Seguro de contar con su colaboración lo saludo atentamente.', marginBottom: 75},
            {
                columns: [
                    {
                        width: '*', 
                        text: [
                            fuente_financiamiento === 'DAF' ? jefe_caja : '',
                            '\n', 
                            {
                                text: fuente_financiamiento === 'DAF' ? 'JEFE DE CAJA' : 'ENCARGADO DE CAJA',
                                style: ['fw-bold']
                            },
                            '\n',
                            'FIRMA',
                        ], 
                        style: ['text-center']
                    },
                    {
                        width: '*', 
                        text: fuente_financiamiento === 'DAF' ? [
                            'Vo.Bo. ',
                            jefe_tesoreria,
                            '\n', 
                            { text: 'JEFE a. i. DPTO. TESORERÍA', style: ['fw-bold']},
                            '\n',
                            'FIRMA',
                        ] : '',
                        style: ['text-center']
                    }
                ]
            }
        ]
        const config = {pageMargins: [42, 42, 42, 42]}
        this.generatePDF(content, null, null, config)
    }

    kardex(movimientos) {
        const content = [{
            table: {
                headerRows: 1,
                widths: ['auto', '*', 80, 80, 80],
                body: [
                    [
                        { text: 'FECHA', style: ['text-center', 'fw-bold'] }, 
                        { text: 'DETALLE', style: ['text-center', 'fw-bold'] },  
                        { text: 'INGRESOS', style: ['text-center', 'fw-bold'] },
                        { text: 'EGRESOS', style: ['text-center', 'fw-bold'] }, 
                        { text: 'SALDO', style: ['text-center', 'fw-bold'] }, 
                    ],
                    ...movimientos.map(movimiento => [
                        { text: formatDate(movimiento.fecha, 'd/m/Y', null) },
                        { text: movimiento.detalle },
                        { text: formatCurrency(movimiento.ingresos), style: 'text-end' },
                        { text: formatCurrency(movimiento.egresos), style: 'text-end' },
                        { text: formatCurrency(movimiento.saldo), style: 'text-end' },
                    ])
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i < 2 || i === node.table.body.length ? 1 : 0
                },
                vLineWidth: function (i) {
                    return 0;
                },
            },
        }]
        this.generateReport('KARDEX DE VIÁTICOS', content)
    }

}

export default ReembolsoPDF