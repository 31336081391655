import { ErrorMessage } from 'formik'
import InputCheckbox from 'components/InputCheckbox'
import InputFormik from 'components/formik/InputFormik'

/** @module Pages/Solicitud/Components/AutorizacionStep */

/**
 * Componente, Sección de Autorización del registro de la Solicitud.
 * @param {object} props Propiedades del componente.
 * @param {object} props.autorizacion Estado de información de autorización.
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setAutorizacion Dispatch para actualizar el estado de autorización.
 * @param {boolean} props.invitados Especifica si la solicitud es de invitados o no.
 * @returns {JSX.Element} Retorna el componente AutorizacionStep.
 */
function AutorizacionStep({ autorizacion, setAutorizacion, invitados }) {
    const {autorizado, resolucion} = autorizacion

    const handleChange = (e) => {
        if (!invitados) {
            const {name, value} = e.target
            const _value = {}
            if (value) {
                if (name === 'autorizado') {
                    _value.resolucion = false
                } else if (name === 'resolucion') {
                    _value.autorizado = false
                }
            }
            setAutorizacion({...autorizacion, ..._value, [name]: value})
        }
    }
    
    return (
        <div>
            <div className='border rounded p-3'>
                <div className='fs-5 mb-3'>
                    ¿Como se autorizo la solicitud? <span className='bi-info-circle text-primary cursor-pointer d-none'></span>
                </div>
                <div className='row gap-2 mb-3'>
                    <label className='col-auto d-flex align-items-center'>
                        <InputCheckbox 
                            name='autorizado' 
                            value={autorizado} onChange={handleChange} 
                            className='me-2' style={{fontSize: '1.5rem'}}
                            disabled={invitados}
                        />
                        Autorizado por el Jefe Inmediato Superior
                    </label>
                    <span className='col-auto text-primary'>Usted debe estar autorizad@ para registrar la Solicitud.</span>
                </div>
                <div className='row gap-2 mb-3'>
                    <label className='col-auto d-flex align-items-center'>
                        <InputCheckbox 
                            name='resolucion' 
                            value={resolucion} onChange={handleChange} 
                            className='me-2' style={{fontSize: '1.5rem'}} 
                            disabled={invitados}
                        />
                        Autorizado con Resolución
                    </label>
                    {resolucion && <span className='col-auto text-primary'>Recuerde adjuntar la Resolución correspondiente a su Solicitud.</span>}
                </div>
                {autorizacion.resolucion 
                    ? <InputFormik name='resolucion' label='Resolución:' inline showError={false} placeholder='Descripción'/> 
                    : null
                }
            </div>
            <ErrorMessage name='autorizacion'>{msg => <div className='d-block invalid-feedback'>{msg}</div>}</ErrorMessage>
        </div>
    )
}
export default AutorizacionStep