import { useRef, useState, useEffect } from 'react'
import { ErrorMessage, FieldArray, Form, Formik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import Button, { ButtonIcon } from 'components/Button'
import { EditableSelectFormik, InputFormik } from 'components/formik/formikFormComponents' 

import { setHttpMessage } from 'store/messageSlice'
import SolicitudService from 'services/solicitud.service'
import { solicitudValidator } from 'utils/validators/validators'
import AutorizacionStep from './AutorizacionStep'
import { Alert, CloseButton } from 'react-bootstrap'
import SearchBeneficiario from 'components/SearchBeneficiario'
import { generateCode } from 'utils/utils'
import Checkbox from 'components/Checkbox'
import ApiService from 'services/api.service'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDate } from 'utils/utils'
import Dialog, { InfoDialog } from 'components/Dialog'
import FormBeneficiario from './FormBeneficiario'
import FormInvitado from './FormInvitado'
import DateTimeFormik from 'components/formik/DateTimeFormik'
import { SelectFormik } from 'components/formik'

/** @module Pages/Solicitud/Components/FormSolicitud */

// Valores iniciales de la información de autorización.
const initialAutorizacion = { autoridad: null, resolucion: false, autorizado: false }
const currentDate = new Date()

// Valores iniciales del destino
const initialDestino = { lugar_viaje: '', fecha_salida: '', fecha_llegada: '', beneficiarios: [] }
// Valores iniciales del formulario
const initialValues = {
    fuente_financiamiento: '',
    identificador: '',
    objetivo_viaje: '',
    actividades: '',
    resultado: '',
    destinos: [{...initialDestino, code: generateCode()}],
    autoridad: null,
    autorizacion: false,
    resolucion: ''
}

/**
 * Componente, Formulario de registro de solicitud.
 * @returns {JSX.Element} Retorna el componente FormSolicitud.
 */
function FormSolicitud() {
    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {sectionKey} = useSelector(state => state.system)
    const [autorizacion, setAutorizacion] = useState(initialAutorizacion)
    const [invitados, setInvitados] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [show, setShow] = useState({success: false})
    const [fuentesFin, setFuentesFin] = useState([])
    const formRef = useRef(null)
    let init = false

    useEffect(() => {
        const loadData = async () => {
            const {status, data} = await ApiService.fuenteFinanciamiento.all()
            if (status === 200) setFuentesFin(data)
        }
        loadData()
    }, [])
    useEffect(() => {
        const loadData = async () =>  {
            init = true
            const response = await SolicitudService.getSolicitudToUpdate(id)
            if (response.status === 200) {
                const {objetivo_viaje, identificador, resolucion, fuente_financiamiento, ...data} = response.data.data 
                const destinos = groupDestinos(data.solicitudBeneficiarios)
                formRef?.current?.resetForm({
                    values: {
                        ...initialValues,
                        identificador,
                        objetivo_viaje,
                        fuente_financiamiento,
                        resolucion,
                        id: data.id,
                        destinos
                    }
                })
                setInvitados(data.invitados)
                setAutorizacion({
                    ...initialAutorizacion, 
                    ...(data.invitados||resolucion ? {resolucion: true} : {}),
                })
            } else {
                dispatch(setHttpMessage({status: response.status, title: response.data.message}))
                navigate(`/${sectionKey}/solicitud`)
            }
        }
        if (id && !init) {
            loadData()
        } else {
            formRef?.current?.resetForm({values: initialValues})
            setInvitados(false)
            setAutorizacion(initialAutorizacion)
        }
    }, [id])

    const openSuccessDialog = () => setShow({...show, success: true})
    const closeSuccessDialog = () => setShow({...show, success: false})

    const groupDestinos = (solBens) => {
        const groups = {}
        solBens.forEach(s => {
            if (groups[s.grupo]) {
                groups[s.grupo].push(s)
            } else {
                groups[s.grupo] = [s]
            }
        })
        return Object.values(groups).map(g => {
            return {
                lugar_viaje: g.length > 0 ? g[0].lugar_viaje : '',
                fecha_salida: g.length > 0 ? g[0].fecha_salida : '',
                fecha_llegada: g.length > 0 ? g[0].fecha_llegada : '',
                code: g.length > 0 ? g[0].grupo : '',
                beneficiarios: g.map(b => {
                    const {apellido_beneficiario, nombre_beneficiario, beneficiario_cargo_id,
                        beneficiario_id, cargo, cargo_id, documento, funcion, origen} = b
                    return {
                        id: b.id,
                        apellido_beneficiario,
                        nombre_beneficiario,
                        beneficiario_cargo_id,
                        beneficiario_id,
                        cargo,
                        cargo_id,
                        documento,
                        funcion,
                        origen
                    }
                })
            }
        })
    }
    const unGroupDestinos = (destinos) => {
        const solBens = []
        destinos.forEach(d => {
            const {beneficiarios, ..._d} = d
            beneficiarios.forEach(b => {
                solBens.push({...b, ..._d}) // Información, beneficiario + destino
            }) 
        })
        return solBens
    }

    const handleSubmit = async (values) => {
        setSubmitting(true) 
        if (id) {
            const data = {
                id: values.id,
                identificador: values.identificador,
                fuente_financiamiento: values.fuente_financiamiento,
                objetivo_viaje: values.objetivo_viaje, 
                solicitudBeneficiarios: unGroupDestinos(values.destinos),
                resolucion: values.resolucion,
                invitados,
            }
            const response = await SolicitudService.updateSolicitud(id, data)
            dispatch(setHttpMessage({
                status: response.status, 
                title: response.data.message, 
                sticky: true,
                detail: response.data.errors?.length>1?response.data.errors[1].replace(/\\n/g, '\n'):''
            }))
            if (response.status === 200) navigate(`/${sectionKey}/solicitud`)
        } else {
            const data = {
                identificador: values.identificador,
                fuente_financiamiento: values.fuente_financiamiento,
                objetivo_viaje: values.objetivo_viaje, 
                solicitudBeneficiarios: unGroupDestinos(values.destinos),
                resolucion: values.resolucion,
                invitados,
            }
            const response = await SolicitudService.createSolicitud(data)
            if (response.status === 201) {
                openSuccessDialog()
                cleanForm()
            } else {
                dispatch(setHttpMessage({status: response.status, title: response.data.message, sticky: true}))
            }
        }
        setSubmitting(false)
    }
    const cleanForm = () => {
        formRef?.current?.resetForm()
        setAutorizacion(initialAutorizacion)
    }
    const validate = (values) => {
        const errors = {}
        // if (!resolucion && !autoridad) errors.autoridad = 'Debe seleccionar quien autorizara su solicitud'
        if (!autorizacion.autorizado && !autorizacion.resolucion) errors.autorizacion = 'Debe seleccionar una de las opciones'
        const errorsDestinos = validateDestinos(values.destinos)
        if (errorsDestinos) errors.destinos = errorsDestinos
        if (invitados && !values.resolucion) errors.resolucion = 'Requerido'
        return errors
    }
    /**
     * Valida el array de destinos
     * @returns objeto de errores o si no hay errores null
     */
    const validateDestinos = (_destinos) => {
        const errors = {}
        _destinos.forEach((destino, index) => {
            const _errors = {}
            if (!destino.lugar_viaje) _errors.lugar_viaje = 'Requerido'
            if (!destino.fecha_salida) _errors.fecha_salida = 'Requerido'
            if (!destino.fecha_llegada) _errors.fecha_llegada = 'Requerido'
            if (destino.fecha_salida && destino.fecha_llegada) {
                const fechaSalida = formatDate(destino.fecha_salida, 'Y-m-d H:i:s')
                const fechaLlegada = formatDate(destino.fecha_llegada, 'Y-m-d H:i:s')
                if (fechaLlegada < fechaSalida) {
                    _errors.fecha_llegada = 'Debe ser mayor a la fecha de salida'
                    _errors.fecha_salida = 'Debe ser menor a la fecha de retorno'
                }
            }
            if (destino.beneficiarios.length === 0)  _errors.beneficiarios = 'Debe añadir por lo menos un beneficiario'
            if (Object.values(_errors).length > 0) errors[index] = _errors
        })
        return Object.values(errors).length > 0 ? errors : null
    }
    const onChangeInvitados = (e) => {
        const value = e.target.value 
        setInvitados(value)
        setAutorizacion({...initialAutorizacion, ...(value ? {resolucion: true} : {})})
    }
    const preventEnter = e=>{
        if (e.target.localName === 'input') {
            if (e.key==='Enter') e.preventDefault()
        }
    }
    const redirectToList = () => {
        navigate(`/${sectionKey}/solicitud`)
    }

    const footerSuccessDialog = <Button variant='outline-success' onClick={redirectToList}>Ir a la lista de solicitudes</Button>

    return (
        <div className='p-4'>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={solicitudValidator}
                validate={validate}
            >{() => (
                <Form onKeyDown={preventEnter}>
                    <div style={{minHeight: '40rem'}}>
                        <div className='row g-3 mb-4'>
                            <InputFormik 
                                name='identificador' 
                                label={<span className='fs-5'>Identificador:</span>} 
                                info='SIDOC, CITE u otro dato identificativo que se encuentre en el documento físico de la solicitud. (Opcional)'
                                placeholder='Ej. SIDOC 0000'
                                containerClassName='col-md-auto flex-first-md'
                                containerStyle={{order: 1}}
                                inline='md'
                            />
                            <div className='col-auto fs-5 ms-auto'>
                                Fecha: {formatDate(currentDate, 'd/m/Y')}
                            </div>
                        </div>
                        <div>
                            <SelectFormik 
                                name='fuente_financiamiento' 
                                label={<div className='fs-5'>Fuente de Financiamiento:</div>}
                                options={fuentesFin}
                                optionLabel='descripcion'
                                optionValue='id'
                                placeholder='Seleccione'
                                inline
                                containerClassName='mb-3'
                            />
                        </div>
                        <div>
                            <InputFormik 
                                name='objetivo_viaje' 
                                label={<div className='fs-5'>Objetivo de viaje:</div>}
                                placeholder='Ej. Asistir a la reunion de ...'
                            />
                        </div>
                        <div>
                            <FieldArray name='destinos'>
                                {({form: {values, errors}, ...arrayHelpers}) => (
                                    <Destinos 
                                        {...arrayHelpers} 
                                        values={values.destinos} 
                                        errors={errors.destinos} 
                                        invitados={invitados} 
                                        onChangeInvitados={onChangeInvitados}
                                        id={id}
                                    />
                                )}
                            </FieldArray>
                        </div>
                        <div>
                            <div className='fs-5 mb-3'>Autorización:</div>
                            <AutorizacionStep 
                                autorizacion={autorizacion} setAutorizacion={setAutorizacion}
                                invitados={invitados}
                            />
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <Button startIcon='pi pi-save' type='submit' loading={submitting}>{id ? 'Guardar' : 'Registrar'}</Button>
                        </div>
                    </div>
                    <Observer invitados={invitados} id={id} />
                </Form>
            )}</Formik>
            <InfoDialog
                show={show.success}
                onAccept={closeSuccessDialog}
                footer={footerSuccessDialog}
                closeButton={false}
                variant='success'
                style={{width: '30rem'}}
            >
                La Solicitud ha sido registrada correctamente. Puede continuar con el envió de la documentación a la DAF.
            </InfoDialog>
        </div>
    )
}
export default FormSolicitud

/**
 * Observador del formulario.
 * @param {object} props Propiedades del componente.
 * @param {boolean} props Especifica si la solicitud es de invitados o no.
 * @returns {null}
 */
function Observer({invitados, id}) {
    const { values, setValues } = useFormikContext()

    /**
     * Limpia el array de beneficiario/invitados cada vez que cambia el valor de invitados
     */
    useEffect(() => {
        !id && setValues({...values, destinos: values.destinos.map(d => ({...d, beneficiarios: []}))})
    }, [invitados])

    return null
}

/**
 * Componente, Destinos de viajes.
 * @param {object} props Propiedades del componente.
 * @param {string} props.name Name de input.
 * @param {array} props.values Array de destinos.
 * @param {function} props.push Función de formik para adicionar un elemento(Destino) al array de Destinos.
 * @param {function} props.remove Función de formik para remover un elemento(Destino) del array de Destinos.
 * @param {function} props.replace Función de formik para reemplazar un elemento(Destino) del array de Destinos.
 * @param {boolean} props.invitados true si los beneficiarios son invitados.
 * @param {function} props.onChangeInvitados Función que cambia el valor de la propiedad invitados.
 * @returns {JSX.Element} Retorna el componente Destinos.
 */
function Destinos({name='', values, push, remove, replace, invitados, onChangeInvitados, id}) {
    const [cargosInvitados, setCargosInvitados] = useState([])

    useEffect(() => {
        const loadData = async () => {
            const response = await ApiService.cargo.getInvitados()
            if (response.status === 200) setCargosInvitados(response.data)
        }
        loadData()
    }, [])

    const handleAdd = () => {
        push({...initialDestino, code: generateCode()})
    }

    return (
        <div>
            <div className='d-flex align-items-center mb-3'>
                <span className='fs-5'>Destino:</span>
                <div className='ms-auto'>
                    <label className='d-flex align-items-center form-switch'>
                        {id 
                            ? <Checkbox value={invitados} disabled style={{fontSize: '1.25rem'}} role='switch'/>
                            : <Checkbox value={invitados} onChange={onChangeInvitados} style={{fontSize: '1.25rem'}} role='switch'/>
                        }
                        <span className='ms-2'>Invitados</span>
                    </label>
                </div>
                <div className='lh-1 ms-3 d-none'>
                    <ButtonIcon icon='pi pi-plus' onClick={handleAdd} title='Nuevo destino'/>
                </div>
            </div>
            <div>
                {values.map((destino, index) => (
                    <Destino 
                        key={destino.code} 
                        name={name}
                        value={destino} 
                        index={index}
                        remove={remove}
                        replace={replace}
                        closeable={index !== 0}
                        invitados={invitados}
                        cargosInvitados={cargosInvitados}
                    />
                ))}
            </div>
        </div>
    )
}

/**
 * Componente, Destino de viaje.
 * @param {object} props Propiedades del componente. 
 * @param {number} props.index Index del destino.
 * @param {boolean} props.invitados true si los beneficiarios son invitados.
 * @param {boolean} props.closeable true si el destino se puede eliminar.
 * @param {object} props.value destino: {lugar_viaje, fecha_salida, fecha_llegada, beneficiarios}.
 * @param {string} props.name Name de input.
 * @param {array} props.cargosInvitados array de cargos disponibles para beneficiarios invitados.
 * @param {function} props.remove Función de formik para remover un elemento(Destino) del array de Destinos.
 * @param {function} props.replace Función de formik para reemplazar un elemento(Destino) del array de Destinos.
 * @returns {JSX.Element} Retorna el componente Destino.
 */
function Destino({value, name, remove, replace, closeable=true, index, invitados, cargosInvitados}) {
    const [lugares, setLugares] = useState([])
    const [show, setShow] = useState({formBen: false, formInv: false})
    const formBenRef = useRef()
    const formInvRef = useRef()

    const openFormBenDialog = () => setShow({...show, formBen: true})
    const closeFormBenDialog = () => setShow({...show, formBen: false})
    const openFormInvDialog = () => setShow({...show, formInv: true})
    const closeFormInvDialog = () => setShow({...show, formInv: false})

    const updateDestino = (name, _value) => {
        replace(index, {...value, [name]: _value})
    }
    const handleSelection = (selection) => {
        if (selection) {
            const _beneficiarios = [...value.beneficiarios.filter(b => b.beneficiario_id!==selection.beneficiario_id), selection]
            updateDestino('beneficiarios', _beneficiarios)
        }
    }
    const removeBeneficiario = (index) => {
        const _beneficiarios = value.beneficiarios.filter((b, i) => i!==index)
        updateDestino('beneficiarios', _beneficiarios)
    }
    const buildCardBeneficiario = (b, index) => (
        <div className='col-xl-6 group d-flex' key={index}>
            <div className='d-flex flex-column justify-content-center flex-1 py-2 px-3' style={{border: '1px solid var(--umss-blue)', borderRight: 0}}>
                <div>Nombres: {b.nombre_beneficiario} {b.apellido_beneficiario}</div>
                <div>Cargo: {b.funcion||b.cargo}</div>
                {invitados 
                    ? <div className='col-6 text-nowrap'>Lugar de origen: {b.origen}</div>
                    : <div className='col-6 text-nowrap'>Documento: {b.documento}</div>
                }
            </div>
            <Button startIcon='pi pi-times' onClick={() => removeBeneficiario(index)} />
        </div>
    )
    const addInvitado = (values) => {
        const newInvitado = {
            nombre_beneficiario: values.nombre_beneficiario.toUpperCase(),
            apellido_beneficiario: values.apellido_beneficiario.toUpperCase(),
            cargo: values.cargo_obj?.cargo,
            cargo_id: values.cargo_obj?.cargo_id,
            origen: values.origen
        }
        const _beneficiarios = [...value.beneficiarios, newInvitado]
        updateDestino('beneficiarios', _beneficiarios)
        closeFormInvDialog()
    }
    const addBeneficiario = (values) => {
        const _values = {
            documento: values.documento,
            cargo: values.cargo.toUpperCase(),
            nombre_beneficiario: values.nombre_beneficiario.toUpperCase(),
            apellido_beneficiario: values.apellido_beneficiario.toUpperCase(),
        }
        const _beneficiarios = [...value.beneficiarios, _values]
        updateDestino('beneficiarios', _beneficiarios)
        closeFormBenDialog()
    }
    const searchLugares = (lugar) => {
        const loadData = async () => {
            const response = await SolicitudService.getLugaresViaje({ lugar: value.lugar_viaje })
            if (response.status === 200) setLugares(response.data)
        }
        if (lugar.length > 0) loadData()
        else setLugares([])
    }

    const formBenFooterDialog = <>
        <Button variant='outline-blue' startIcon='pi pi-times' onClick={closeFormBenDialog}>Cancelar</Button>
        <Button startIcon='pi pi-plus' type='submit' onClick={()=>formBenRef?.current?.handleSubmit()}>Añadir</Button>
    </>
    const formInvFooterDialog = <>
        <Button variant='outline-blue' startIcon='pi pi-times' onClick={closeFormInvDialog}>Cancelar</Button>
        <Button startIcon='pi pi-plus' type='submit' onClick={()=>formInvRef?.current?.handleSubmit()}>Añadir</Button>
    </>
    
    return (
        <div className='row g-0 border rounded mb-3'>
            <div className='col-md-4 p-3 border-end'>
                <EditableSelectFormik
                    options={lugares} 
                    lazy
                    onSearch={searchLugares}
                    info='Escribir o elegir el lugar de viaje.'
                    label='Lugar de viaje'
                    name={`${name}.${index}.lugar_viaje`} 
                />
                <DateTimeFormik 
                    name={`${name}.${index}.fecha_salida`} 
                    label='Fecha de salida'
                    info='Fecha de salida del viaje.'
                    placeholderDate='dd/mm/yyyy'
                    placeholderTime='hh:mm'
                />
                <DateTimeFormik 
                    name={`${name}.${index}.fecha_llegada`} 
                    label='Fecha de retorno'
                    info='Fecha de retorno del viaje.'
                    placeholderDate='dd/mm/yyyy'
                    placeholderTime='hh:mm'
                    defaultTime='23:59'
                />
            </div>
            <div className='col-md-8 flex-1 p-3'>
                <div className='d-flex align-items-center mb-3'>
                    <span>{invitados ? 'Invitados' : 'Beneficiarios'}</span>
                    <div className='d-flex align-items-center ms-auto lh-1'>
                        <ButtonIcon icon='pi pi-plus' onClick={invitados?openFormInvDialog:openFormBenDialog} rounded title='Añadir'/>
                        {closeable && <CloseButton className='ms-2' onClick={() => remove(index)} title='Eliminar'/>}
                    </div>
                </div>
                {!invitados && (
                    <SearchBeneficiario 
                        label='Buscar:'
                        placeholder='CI o Documento'
                        onSelect={handleSelection}
                        cleanOnSelect
                        description={<div className='mb-3' style={{fontSize: '1.1rem'}}>Seleccione el beneficiario con el Cargo correspondiente.</div>}
                    />
                )}
                <ErrorMessage name={`${name}.${index}.beneficiarios`}>{msg => <div className='d-block invalid-feedback'>{msg}</div>}</ErrorMessage>
                <div className='row g-3 py-3' style={{minHeight: '10rem'}}>
                    {value.beneficiarios.map(buildCardBeneficiario)}
                </div>
            </div>
            <Dialog 
                show={show.formBen}
                header='Añadir Beneficiario'
                footer={formBenFooterDialog}
                keyboard={false}
                style={{ width: '30rem' }}
                onHide={closeFormBenDialog}
            >
                <Alert variant='primary' className='py-2'>
                    Antes de añadir el Beneficiario manualmente intente buscarlo por su Documento de Identidad
                </Alert>
                <FormBeneficiario formRef={formBenRef} onSubmit={addBeneficiario} />
            </Dialog> 
            <Dialog 
                show={show.formInv}
                header='Añadir Invitado'
                footer={formInvFooterDialog}
                keyboard={false}
                style={{ width: '30rem' }}
                onHide={closeFormInvDialog}
            >
                <Alert variant='primary' className='py-2'>
                    Los Invitados son personas no pertenecientes a la UMSS
                </Alert>
                <FormInvitado
                    formRef={formInvRef}
                    onSubmit={addInvitado}
                    cargosInvitados={cargosInvitados}
                />
            </Dialog> 
        </div>
    )
}